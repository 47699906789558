import Vue from 'vue'
import Router from 'vue-router'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
/*import Home from "@/components/pages/Home";
import ERV from "@/components/ERV";
import Apps from "@/components/pages/Apps";
import Testing from "@/components/pages/Testing";
import uiux from "@/components/pages/uiux";
import Mobility from "@/components/pages/Mobility";
import Cloud from "@/components/pages/Cloud";
import Web from "@/components/pages/Web";
import Solutions from "@/components/pages/Solutions";
import Brief from "@/components/pages/Brief";
import Contact from "@/components/pages/Contact";*/
Vue.use(Router)

function lazyLoad(view){
    return() => import(`@/${view}.vue`)
}
const router = new Router({
    mode: 'history',
    hash: false,
    routes: [

        {
            path: '/',
            component: lazyLoad('components/ERV'),
            children: [
                {
                    name: 'Home',
                    path: '',
                    component: lazyLoad('components/pages/Home')
                },

                {
                    name: 'Apps',
                    path: '/apps',
                    component:  lazyLoad('components/pages/Apps')
                },

                {
                    name: 'Testing',
                    path: '/testing',
                    component: lazyLoad('components/pages/Testing')
                },

                {
                    name: 'UI/UX',
                    path: '/uiux',
                    component:  lazyLoad('components/pages/uiux')
                },

                {
                    name: 'Mobility',
                    path: '/mobility',
                    component:  lazyLoad('components/pages/Mobility')
                },

                {
                    name: 'Cloud',
                    path: '/cloud',
                    component: lazyLoad('components/pages/Cloud')
                },

                {
                    name: 'Web',
                    path: '/web',
                    component: lazyLoad('components/pages/Web')
                },

                {
                    name: 'Solutions',
                    path: '/solutions',
                    component: lazyLoad('components/pages/Solutions')
                },

                {
                    name: 'Brief',
                    path: '/brief',
                    component: lazyLoad('components/pages/Brief')
                },

                {
                    name: 'Contact',
                    path: '/contact',
                    component: lazyLoad('components/pages/Contact')
                }
            ]
        }
     ]
});

export default router;
